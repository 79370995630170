import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, Output } from "@angular/core";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-view-test-result-button",
  templateUrl: "./view-test-result-button.component.html",
  styleUrls: ["./view-test-result-button.component.css"],
})
export class ViewTestResultButtonComponent implements OnInit {
  @Input() rowData: any;
  @Output() copyTest: any;
  constructor(public ds: DataService, public http: HttpClient) { }

  ngOnInit() { }

  copyQuestions() {

    this.http.get(this.ds.strapiServerUrl + `test-question-correct-options?[test_question_id.testID]=${this.rowData.id}&_limit=100&_publicationState=preview`).subscribe(d => {
      console.log(d);


      this.ds.eventListeners.testCopy.emit(d)
      // this.copyTest.emit(d)
    })
  }
  viewResult() {
    window.open(
      window.location.origin + "/testreport/" + this.rowData.id,
      "_blank"
    );
  }
}
