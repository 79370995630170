import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { Ng2SmartTableModule } from "ng2-smart-table";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbMenuModule,
  NbCardModule,
  NbCheckboxModule,
  NbInputModule,
  NbButtonModule,
  NbActionsModule,
  NbUserModule,
  NbRadioModule,
  NbDatepickerModule,
  NbSelectModule,
  NbIconModule,
  NbSpinnerModule,
  NbTabsetModule,
} from "@nebular/theme";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { CityComponent } from "./pages/city/city.component";
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { CourseComponent } from "./pages/course/course.component";
import { SubjectComponent } from "./pages/subject/subject.component";
import { HttpClientModule } from "@angular/common/http";
import { MastersComponent } from "./masters/masters.component";
import { DataService } from "./services/data.service";
import { FormsModule } from "@angular/forms";
import { CityMasterComponent } from "./components/city-master/city-master.component";
import { SubjectMasterComponent } from "./components/subject-master/subject-master.component";
import { CenterMasterComponent } from "./components/center-master/center-master.component";
import { BookTestComponent } from "./components/book-test/book-test.component";
import { BookTestMasterComponent } from "./components/book-test-master/book-test-master.component";
import { SlotMasterComponent } from "./components/slot-master/slot-master.component";
import { ReportComponent } from "./pages/report/report.component";
import { UploadReportButtonComponent } from "./components/upload-report-button/upload-report-button.component";
import { ViewReportButtonComponentComponent } from "./components/view-report-button-component/view-report-button-component.component";
import { DownloadReportButtonComponentComponent } from "./components/download-report-button-component/download-report-button-component.component";
import { SliderComponent } from "./pages/slider/slider.component";
import { SliderImageComponent } from "./components/slider-image/slider-image.component";
import { ResultComponent } from "./pages/result/result.component";
import { ResultUploadComponent } from "./components/result-upload/result-upload.component";
import { VideoComponent } from "./pages/video/video.component";
import { VideoUploadComponent } from "./components/video-upload/video-upload.component";
import { PdfSolutionUploadComponent } from "src/app/components/pdf-solution-upload/pdf-solution-upload.component";
import { GetQuestionsComponent } from "./components/get-questions/get-questions.component";
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { QuestionEditorComponent } from "./components/question-editor/question-editor.component";
import { QuestionNumberComponent } from "./components/question-number/question-number.component";
import { CreateTestComponent } from "./pages/create-test/create-test.component";
import { UploadSolutionCSVComponent } from "./components/upload-solution-csv/upload-solution-csv.component";
import { GetSolutionsComponent } from "./components/get-solutions/get-solutions.component";
import { QuestionUploadVideoComponent } from "./components/question-upload-video/question-upload-video.component";
import { QuestionUpdateNumberComponent } from "./components/question-update-number/question-update-number.component";
import { SliderThumbnailComponent } from "./components/slider-thumbnail/slider-thumbnail.component";
import { SliderVideoComponent } from "./components/slider-video/slider-video.component";
import { SliderImageTestimonialComponent } from "./components/slider-image-testimonial/slider-image-testimonial.component";
import { RevisionNoteComponentComponent } from "./components/revision-note-component/revision-note-component.component";
import { SubCourseComponent } from "./pages/sub-course/sub-course.component";
import { AddChapterComponent } from "./components/add-chapter/add-chapter.component";
import { UploadsComponent } from "./components/uploads/uploads.component";
import { ViewLibraryPdfComponent } from "./components/view-library-pdf/view-library-pdf.component";
import { ViewVideoComponent } from "./components/view-video/view-video.component";
import { LiveComponent } from "./components/live/live.component";
import { StudentComponent } from "./components/student/student.component";
import { StudentSubscriptionComponent } from "./components/student-subscription/student-subscription.component";
import { StudentSubscriptionEditButtonComponent } from "./components/student-subscription-edit-button/student-subscription-edit-button.component";
import { AuthGuard } from "./services/auth.guard";
import { FreeNotesComponent } from "./masters/free-notes/free-notes.component";
import { FreeVideosComponent } from "./masters/free-videos/free-videos.component";
import { AddTestQuestionComponent } from "./test/add-test-question/add-test-question.component";
import { AddTestComponent } from "./test/add-test/add-test.component";
import { ImportantQuestionsComponent } from "./important-questions/important-questions.component";
import { AssignmentComponent } from "./assignment/assignment.component";
import { ProgressComponent } from "./progress/progress.component";
import { TestReportComponent } from "./pages/test-report/test-report.component";
import { ViewButtonComponent } from "./components/student/view-button/view-button.component";
import { ViewTestResultButtonComponent } from "./app/test/view-test-result-button/view-test-result-button.component";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ReportComponent,
    CityComponent,
    HomeComponent,
    LoginComponent,
    CourseComponent,
    SubjectComponent,
    MastersComponent,
    CityMasterComponent,
    SubjectMasterComponent,
    CenterMasterComponent,
    BookTestComponent,
    BookTestMasterComponent,
    SlotMasterComponent,
    ReportComponent,

    UploadReportButtonComponent,

    ViewReportButtonComponentComponent,

    DownloadReportButtonComponentComponent,

    SliderComponent,

    SliderThumbnailComponent,
    SliderVideoComponent,

    SliderImageComponent,
    RevisionNoteComponentComponent,
    SliderImageTestimonialComponent,

    ResultComponent,

    ResultUploadComponent,

    VideoComponent,
    StudentComponent,
    VideoUploadComponent,

    PdfSolutionUploadComponent,

    GetQuestionsComponent,

    QuestionEditorComponent,
    QuestionNumberComponent,
    CreateTestComponent,
    UploadSolutionCSVComponent,
    GetSolutionsComponent,
    QuestionUploadVideoComponent,
    QuestionUpdateNumberComponent,
    SliderThumbnailComponent,
    SliderVideoComponent,
    SliderImageTestimonialComponent,
    RevisionNoteComponentComponent,
    SubCourseComponent,
    AddChapterComponent,
    UploadsComponent,
    ViewLibraryPdfComponent,
    ViewVideoComponent,
    LiveComponent,
    StudentComponent,
    StudentSubscriptionComponent,
    StudentSubscriptionEditButtonComponent,
    FreeNotesComponent,
    FreeVideosComponent,
    AddTestQuestionComponent,
    AddTestComponent,
    ImportantQuestionsComponent,
    AssignmentComponent,
    ProgressComponent,
    TestReportComponent,
    ViewButtonComponent,
    ViewTestResultButtonComponent,
  ],
  imports: [
    FormsModule,
    NbTabsetModule,
    Ng2SmartTableModule,
    HttpClientModule,
    NbSidebarModule.forRoot(),
    BrowserModule,
    NbMenuModule.forRoot(),
    NbCheckboxModule,
    NbCardModule,
    NbInputModule,
    NbThemeModule,
    NbInputModule,
    NbCardModule,
    NbSpinnerModule,

    NbSpinnerModule,

    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    // FormsRoutingModule,
    NbSelectModule,
    NbIconModule,

    // ngFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: "default" }),
    NbLayoutModule,
    NbEvaIconsModule,

    // CKEditorModule,
  ],
  providers: [DataService, AuthGuard],
  bootstrap: [AppComponent],
  entryComponents: [
    ViewTestResultButtonComponent,
    StudentSubscriptionEditButtonComponent,
    StudentSubscriptionComponent,
    UploadReportButtonComponent,
    DownloadReportButtonComponentComponent,
    ViewReportButtonComponentComponent,
    VideoUploadComponent,
    ViewLibraryPdfComponent,
    ViewButtonComponent,
    ResultUploadComponent,
    SliderImageComponent,
    RevisionNoteComponentComponent,
    SliderImageTestimonialComponent,
    SliderThumbnailComponent,
    SliderVideoComponent,
    PdfSolutionUploadComponent,
    QuestionEditorComponent,
    QuestionNumberComponent,
    UploadSolutionCSVComponent,
    QuestionUploadVideoComponent,
    GetSolutionsComponent,
    QuestionUpdateNumberComponent,
    GetQuestionsComponent,
    ViewVideoComponent,
    StudentComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
